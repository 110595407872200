import styled from "styled-components"

import BackIcon from "@material-ui/icons/ArrowBackRounded"

import { useRouter } from "src/router/useRouter"
import { InternalLink, TInternalLinkProps } from "src/ui/Link/InternalLink"
import { spacing } from "src/ui/spacing"

export function BackButton({ to, children, ...props }: BackButtonProps) {
  const { goBack } = useRouter()
  return (
    <Box>
      <StyledLink
        to={to}
        {...props}
        onClick={(e) => {
          e.preventDefault()
          goBack({ defaultPath: to })
        }}
      >
        <StyledBackIcon />
        {children}
      </StyledLink>
    </Box>
  )
}

interface BackButtonProps extends TInternalLinkProps {
  children?: React.ReactNode
}

const Box = styled.div`
  margin-bottom: ${spacing.L};
`

const StyledLink = styled(InternalLink)`
  display: flex;
  align-items: center;
`

const StyledBackIcon = styled(BackIcon)`
  margin-right: ${spacing.XS2};
`
